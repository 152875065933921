<template>
    <div>
        <MainHero
            v-if="hero"
            :title="hero.title"
            :subtitle="hero.content"
            :img-src="hero.image"
            :img-src-mobile="hero.mobile_image"
            :button-label="hero.button_label"
            :button-url="hero.button_url"
            :button-type="hero.button_type"
        />

        <div v-if="notifications">
            <NotificationBlock
                v-for="notification in notifications"
                :key="notification.id"
                :icon="notification.icon"
                :title="notification.title"
                :subtitle="notification.lead"
                :slug="notification.slug"
            />
        </div>

        <section class="container mt-10 lg:mt-20 mb-12">
            <h2 class="offscreen"><T t="generic.why_choose_us" /></h2>
            <div class="grid grid-cols-12">
                <div class="col-span-12 md:col-span-4">
                    <h3 class="subtitle mb-2 lg:mb-4">
                        <T t="homepage.features.pioneers_in_laser_dentistry_title" />
                    </h3>
                    <p class="mb-4 md:mb-0">
                        <T t="homepage.features.pioneers_in_laser_dentistry_description" />
                    </p>
                </div>
                <div class="col-span-12 md:col-span-4 relative">
                    <h3 class="subtitle mb-2 lg:mb-4"><T t="homepage.features.complex_problems_title" /></h3>
                    <p class="mb-4 md:mb-0">
                        <T t="homepage.features.complex_problems_description" />
                    </p>
                </div>
                <div class="col-span-12 md:col-span-4">
                    <h3 class="subtitle mb-2 lg:mb-4">
                        <T t="homepage.features.high_tech_title" />
                    </h3>
                    <p class="mb-4 md:mb-0">
                        <T t="homepage.features.high_tech_description" />
                    </p>
                </div>
            </div>
        </section>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* Knowledge & protocol */ }}
        {{ /* * */ }}

        <section class="overflow-hidden">
            <div class="container relative py-12 md:pt-22 md:pb-28">
                {{ /* wave bg */ }}
                <div
                    class="
                        absolute
                        w-[200%]
                        h-[200%]
                        md:w-[120%] md:h-[120%]
                        top-0
                        left-2/3
                        -translate-x-1/2
                        z-0
                        md:-left-44 md:-translate-x-5
                        lg:-left-80 lg:w-full lg:h-full
                        3xl:-left-96
                    "
                >
                    <div
                        class="aspect-w-11 aspect-h-5 bg-contain bg-left-top bg-no-repeat"
                        v-lazy-background :lazy-background="require('~/assets/images/generic/wave.svg')"
                    ></div>
                </div>

                {{ /* content */ }}
                <div class="flex flex-col md:flex-row items-center relative z-10">
                    <div class="w-full md:w-5/12 lg:w-4/12 z-10">
                        <div
                            class="aspect-h-1 aspect-w-1 w-full rounded-lg overflow-hidden"
                            style="box-shadow: 0 4px 30px rgba(28, 130, 150, 0.2)"
                        >
                            <img
                                v-lazy-load
                                class="object-cover"
                                :data-src="require('~/assets/images/generic/dr-fulop-papp-marta-fooldal.webp')"
                                :alt="$t('homepage.knowledge_and_protocol.title')"
                            />
                        </div>
                    </div>
                    <div
                        class="
                            bg-white
                            rounded-bl-lg rounded-br-lg
                            w-11/12
                            mx-auto
                            py-6
                            px-5
                            z-20
                            md:rounded-tr-lg
                            md:rounded-tl-none
                            md:rounded-bl-none
                            md:-ml-0
                            md:w-7/12
                            md:z-0
                            md:px-10
                            md:py-10
                            lg:pr-15 lg:pl-16 lg:py-16 lg:w-7/12
                        "
                        style="box-shadow: 0 4px 30px rgba(28, 130, 150, 0.2)"
                    >
                        <span class="subtitle"><T t="homepage.knowledge_and_protocol.title" /></span>
                        <p class="mt-4 text-dark-600">
                            <T t="homepage.knowledge_and_protocol.description" />
                        </p>
                    </div>
                </div>
            </div>
        </section>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* Our services */ }}
        {{ /* * */ }}

        <section v-if="serviceCategories.length" class="container mt-8 md:mt-12 lg:mt-20 mb-15 md:mb-24 lg:mb-32">
            <h2 class="title text-center mb-4 lg:mb-6">
                <T t="core.services" />
            </h2>
            <p class="subtitle text-dark-500 text-center mb-12 lg:mb-16">
                <T t="homepage.services_description" />
            </p>

            <div v-for="serviceCategory in serviceCategories" :key="`service-category-${serviceCategory.id}`">
                <div v-if="serviceCategory.services.length">
                    <h3 class="title-2 text-center mb-8 lg:mb-10">{{ serviceCategory.title }}</h3>
                    <div class="grid grid-cols-4 mb-12 lg:mb-20">
                        <div
                            v-for="(service, i) in serviceCategory.services"
                            :key="`service-card-${service.id}`"
                            class="col-span-4 md:col-span-2"
                            :class="{ 'odd:md:col-start-2': i === serviceCategory.services.length - 1 }"
                        >
                            <ServiceCard
                                :icon="service.icon"
                                :title="service.title"
                                :description="service.lead"
                                :path="localePath({ name: 'services-service', params: { service: service.slug } })"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* What is laser surgery good for */ }}
        {{ /* * */ }}

        <InfoBlock />

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* How can we help searchbar */ }}
        {{ /* * */ }}

        <div class="container mt-16 lg:mt-24 mb-20 lg:mb-32">
            <h2 class="title text-center mb-5 2xl:mt-32">
                <T t="homepage.dental_problem_title" />
            </h2>
            <div class="grid grid-cols-12">
                <div class="col-span-12 lg:col-start-3 lg:col-span-8 relative mt-4 lg:mt-9">
                    <SearchBlock
                        type="slim"
                        :placeholder="$t('generic.search_for_dental_problems')"
                        context="dental-problems"
                    />

                    <div class="text-center mt-11 lg:mt-15">
                        <ButtonLink
                            :to="localePath('dental-problems')"
                            :label="$t('generic.see_the_full_list')"
                            class="p-button-md/lg xs-w-full"
                        />
                    </div>
                </div>
            </div>
        </div>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* Reference works */ }}
        {{ /* * */ }}

        <section v-if="comparisons.length" class="bg-primary-100 py-8 md:py-15">
            <h2 class="title text-center mb-12">
                <T t="core.our_works" />
            </h2>
            <div class="container">
                <div class="grid grid-cols-12">
                    <div
                        v-for="(comparison, i) in comparisons"
                        :key="`comparison-${comparison.id}`"
                        class="col-span-12 md:col-span-4"
                        :class="{
                            'hidden md:block': i > 0,
                            'md:col-start-3': i === 0 && comparisons.length === 2,
                            'md:col-start-5': i === 0 && comparisons.length === 1,
                        }"
                    >
                        <ComparisonCard
                            :before="comparison.image_before"
                            :after="comparison.image_after"
                            :label="comparison.title"
                        />
                    </div>
                </div>

                <div class="text-center mt-11 lg:mt-15">
                    <ButtonLink
                        :to="localePath('works')"
                        :label="$t('generic.see_more')"
                        class="p-button-lg xs-w-full"
                    />
                </div>
            </div>
        </section>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* Testimonials */ }}
        {{ /* * */ }}

        <section v-if="testimonials.length" class="mt-16 lg:mt-32 mb-16 lg:mb-36 xl:mb-32">
            <CustomerReviewCarousel
                generic
                :title="$t('testimonial.homepage_title')"
                :reviews="testimonials"
                :button="{
                    label: $t('testimonial.see_other_reviews'),
                    url: localePath('about-testimonials'),
                }"
            />
        </section>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* Our dentists - Dr. Fülöp Papp Márta MSc. */ }}
        {{ /* * */ }}

        <section>
            <div v-if="chiefDoctor" class="container">
                <h2 class="title text-center mb-10 lg:mb-15"><T t="core.our_team" /></h2>
                <div class="grid grid-cols-12 mb-4 lg:mb-7">
                    {{ /* * */ }}
                    {{ /* image */ }}

                    <div class="col-span-6 sm:col-span-4 md:col-span-6 lg:row-span-2 relative">
                        <div
                            class="
                                featured-dentist-image-container
                                lg:absolute
                                w-full
                                h-full
                                right-0
                                overflow-hidden
                                3xl:left-0
                                rounded-2xl
                            "
                        >
                            <div
                                class="
                                    featured-dentist-image-container__inner
                                    aspect-w-4 aspect-h-5
                                    xl:relative xl:h-auto
                                "
                            >
                                <NuxtLink
                                    :to="
                                        localePath({
                                            name: 'about-dentists-dentist',
                                            params: { dentist: chiefDoctor.slug },
                                        })
                                    "
                                >
                                    <img
                                        v-lazy-load
                                        class="absolute w-full h-full object-cover object-center rounded-2xl"
                                        :data-src="chiefDoctor.image"
                                        :alt="chiefDoctor.name"
                                    />
                                </NuxtLink>
                            </div>
                        </div>
                    </div>

                    {{ /* * */ }}
                    {{ /* description */ }}
                    <div class="col-span-6 sm:col-span-8 md:col-span-6 font">
                        <h3 class="subtitle mb-6">{{ chiefDoctor.name }}</h3>
                        <h4 class="font-HKGrotesk-Bold mb-1 lg:mb-3 hidden md:block">
                            <T t="dentist.introduction" />
                        </h4>
                        <div class="hidden md:block" v-html="chiefDoctor.description"></div>
                        <ButtonLink
                            class="p-button-outlined w-full xs:w-max mt-7 md:mt-8 mb-7"
                            :label="$t('generic.more_about')"
                            :to="localePath({ name: 'about-dentists-dentist', params: { dentist: chiefDoctor.slug } })"
                        />
                        <h4 class="font-HKGrotesk-Bold mb-1 lg:mb-3">
                            <T t="dentist.specializations" />
                        </h4>
                        <ul>
                            <li v-for="tag in chiefDoctor.tags" :key="`chief-doctor-tag-${tag.id}`">{{ tag.name }}</li>
                        </ul>
                    </div>

                    {{ /* * */ }}
                    {{ /* bio link that only appears on mobile */ }}
                    <div class="col-span-12 md:hidden md">
                        <NuxtLink
                            :to="localePath({ name: 'about-dentists-dentist', params: { dentist: chiefDoctor.slug } })"
                            class="
                                w-full
                                font-HKGrotesk-Bold
                                border-b border-light-350
                                flex
                                justify-between
                                items-center
                                py-3
                            "
                        >
                            <span><T t="dentist.detailed_introduction" /></span>
                            <i class="ni ni-angle-arrow-right text-xl"></i>
                        </NuxtLink>
                    </div>

                    {{ /* * */ }}
                    {{ /* quote */ }}
                    <div class="col-span-12 lg:col-span-5">
                        <blockquote class="quote pb-6 relative">
                            {{ chiefDoctor.quote }}
                        </blockquote>
                    </div>
                </div>
            </div>

            {{ /* * */ }}
            {{ /* Other dentists */ }}
            <div v-if="team.length" class="container xl:max-w-[1410px] 3xl:p-0">
                <SectionEmployeeGrid :employees="team" />
            </div>
        </section>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* Get to know us */ }}
        {{ /* * */ }}

        <div class="container mt-24 lg:mt-48 lg:pt-2 mb-10 lg:mb-48">
            <h2 class="title text-center mb-10 lg:mb-20">
                <T t="homepage.get_to_know_us.title" />
            </h2>

            <div class="grid grid-cols-12">
                <div class="col-span-12 lg:col-span-4">
                    <FeatureCard
                        :icon="require('~/assets/images/icons/services/icon-vip.svg')"
                        :title="$t('homepage.get_to_know_us.vip_environment_title')"
                        :description="$t('homepage.get_to_know_us.vip_environment_description')"
                    />
                </div>
                <div class="col-span-12 lg:col-span-4">
                    <FeatureCard
                        :icon="require('~/assets/images/icons/services/icon-holisztikus.svg')"
                        :title="$t('homepage.get_to_know_us.holistic_view_title')"
                        :description="$t('homepage.get_to_know_us.holistic_view_description')"
                    />
                </div>

                <div class="col-span-12 lg:col-span-4">
                    <FeatureCard
                        :icon="require('~/assets/images/icons/services/icon-megelozo.svg')"
                        :title="$t('homepage.get_to_know_us.preventive_view_title')"
                        :description="$t('homepage.get_to_know_us.preventive_view_description')"
                    />
                </div>
            </div>
        </div>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* Our mission */ }}
        {{ /* * */ }}

        <div v-if="misc.length" class="container">
            <AboutUsBlock :stats="misc" />
        </div>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* Contact info */ }}
        {{ /* * */ }}

        <section class="bg-light-200 pt-8 lg:py-24 mt-14 lg:mt-36 mb-20 overflow-hidden">
            <div class="container">
                <h2 class="title text-center mb-12">
                    <T t="generic.contact_info" />
                </h2>

                <MapBlock />
            </div>
        </section>

        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* * */ }}
        {{ /* Featured articles */ }}
        {{ /* * */ }}

        <section v-if="articles.length" :class="$device.isMobileOrTablet ? 'sm:container' : 'container'">
            <ArticleCardGrid :title="$t('generic.popular_articles')" :articles="articles" />
        </section>
    </div>
</template>

<script>
import MainHero from '@/components/Heroes/MainHero/MainHero';
import AboutUsBlock from '@/components/Sections/AboutUsBlock/AboutUsBlock';
import SearchBlock from '@/components/Sections/SearchBlock/SearchBlock';
import ArticleCardGrid from '@/components/Sections/ArticleCardGrid/ArticleCardGrid';
import ComparisonCard from '@/components/Cards/ComparisonCard/ComparisonCard';
import MapBlock from '@/components/Sections/MapBlock/MapBlock';
import InfoBlock from '@/components/Sections/InfoBlock/InfoBlock';
import SectionEmployeeGrid from '@/components/Sections/SectionEmployeeGrid/SectionEmployeeGrid';
import ServiceCard from '@/components/Cards/ServiceCard/ServiceCard';
import FeatureCard from '@/components/Cards/FeatureCard/FeatureCard';
import CustomerReviewCarousel from '@/components/Carousels/CustomerReviewCarousel/CustomerReviewCarousel';
import NotificationBlock from '@/components/Sections/NotificationBlock/NotificationBlock';

export default {
    name: 'Index',
    components: {
        MainHero,
        ArticleCardGrid,
        SearchBlock,
        ComparisonCard,
        FeatureCard,
        ServiceCard,
        AboutUsBlock,
        InfoBlock,
        SectionEmployeeGrid,
        MapBlock,
        CustomerReviewCarousel,
        NotificationBlock,
    },
    async asyncData({ $axios, i18n }) {
        let hero = null;
        let chiefDoctor = null;
        let comparisons = [];
        let serviceCategories = [];
        let testimonials = [];
        let articles = [];
        let team = {};
        let notifications = [];
        let misc = [];

        try {
            await Promise.allSettled([
                $axios.$get('/before-after'),
                $axios.$get('/headline'),
                $axios.$get('/service/list?is_highlighted=1'),
                $axios.$get('/testimonial?is_highlighted=1'),
                $axios.$get('/article?is_highlighted=1&limit=3'),
                $axios.$get('/team'),
                $axios.$get('/notification'),
                $axios.$get(''),
            ])
                .then(
                    ([
                        comparisonRes,
                        headlineRes,
                        servicesRes,
                        testimonialRes,
                        articleRes,
                        teamRes,
                        notificationRes,
                        miscRes,
                    ]) => {
                        if (comparisonRes.status === 'fulfilled' && comparisonRes.value.success === 1) {
                            comparisons = comparisonRes.value.data;
                        }

                        if (headlineRes.status === 'fulfilled' && headlineRes.value.success === 1) {
                            hero = headlineRes.value.data[0] || {
                                title: i18n.$t('homepage.hero.fallback_title'),
                                subtitle: i18n.$t('homepage.hero.subtitle'),
                                image: require('~/assets/images/_dev/hero_sample.jpg'),
                                mobile_image: require('~/assets/images/_dev/hero_sample.jpg'),
                                button_label: i18n.$t('homepage.hero.fallback_button_label'),
                                button_url: 'appointment-booking',
                            };
                        }

                        if (servicesRes.status === 'fulfilled' && servicesRes.value.success === 1) {
                            serviceCategories = servicesRes.value.data;
                        }

                        if (testimonialRes.status === 'fulfilled' && testimonialRes.value.success === 1) {
                            testimonials = testimonialRes.value.data;
                        }

                        if (articleRes.status === 'fulfilled' && articleRes.value.success === 1) {
                            articles = articleRes.value.data;
                        }

                        if (teamRes.status === 'fulfilled' && teamRes.value.success === 1) {
                            chiefDoctor = teamRes.value.data.find((member) => member.is_chief_physician);
                            team = teamRes.value.data.filter(
                                (member) => member.is_highlighted && !member.is_chief_physician
                            );
                        }

                        if (notificationRes.status === 'fulfilled' && notificationRes.value.success === 1) {
                            notifications = notificationRes.value.data;
                        }

                        if (miscRes.status === 'fulfilled' && miscRes.value.success === 1) {
                            misc = miscRes.value.data.misc;
                        }
                    }
                )
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (err) {
            console.log(err);
        }

        return {
            comparisons,
            hero,
            serviceCategories,
            testimonials,
            articles,
            team,
            chiefDoctor,
            notifications,
            misc,
        };
    },
    data() {
        return {
            NotificationIsActive: true,
            isDropdown1Open: false,
        };
    },
    head() {
        return {
            script: [
                {
                    type: 'application/ld+json',
                    json: {
                        '@context': 'http://schema.org',
                        '@type': 'Dentist',
                        name: 'Naturadent Esztétikai és Lézerfogászat',
                        image: 'og-image.jpg',
                        address: {
                            '@type': 'PostalAddress',
                            streetAddress: 'Váci utca 81.',
                            addressLocality: 'Budapest',
                            addressRegion: 'HU',
                            postalCode: '1056',
                            addressCountry: 'HU',
                        },
                        geo: {
                            '@type': 'GeoCoordinates',
                            latitude: 47.48814729202853,
                            longitude: 19.056708557008506,
                        },
                        telephone: '+36 1 789 0690',
                        openingHours: ['Mo-Th 08:00-20:00', 'Fr 08:00-15:00'],
                        priceRange: '$$',
                        url: 'https://naturadent.hu',
                        email: 'info@naturadent.hu',
                    },
                },
            ],
        };
    },
    mounted() {
        if (window.location.host === 'm.naturadent.hu') {
            window.location.href = 'https://naturadent.hu/';
        }
    },
};
</script>

<style scoped>
.quote {
    &::before {
        content: '';
        @apply block absolute xl:-left-7 bottom-0 w-[160px] h-[128px] md:w-[190px] md:h-[148px] bg-contain bg-no-repeat bg-center -z-1;
        background-image: url('~/assets/images/generic/quotation-mark.svg');
    }
}

.featured-dentist-image-container {
    @media (min-width: 1280px) {
        width: calc(100vw / 2 - 40px);
        max-width: 675px;
        padding-right: 65px;
    }
}
</style>
